import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { AppProvider } from '../contexts/app/AppContext';
import { GlobalStyles } from '../utils/globalStyles';
import { theme } from '../utils/theme';
import { SessionProvider } from 'next-auth/react';
import { Toaster } from 'react-hot-toast';
import 'react-chat-elements/dist/main.css';
import '../components/multirange-slider/multiRangeSlider.css';
import MaintenancePage from './maintenance';

//Binding events.
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

NProgress.configure({ showSpinner: false });

const App = ({ Component, pageProps: { session, ...pageProps } }: any) => {
  let Layout = Component.layout || Fragment;

  // Check the maintenance flag from environment variables
  const isMaintenanceMode = process.env.MAINTENANCE_FLAG === 'true';

  if (isMaintenanceMode) {
    return <MaintenancePage />;
  }

  return (
    <ThemeProvider theme={theme}>
      <SessionProvider session={session}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />

          {/* thumbnail And title for social media */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Teakita" />
          <meta property="og:description" content="Malaysia best online furniture platform." />
        </Head>
        <GlobalStyles />
        <AppProvider>
          <Layout>
            <Component {...pageProps} />
            <Toaster
              toastOptions={{
                position: 'top-center',
              }}
            />
          </Layout>
        </AppProvider>
      </SessionProvider>
    </ThemeProvider>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// App.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

export default App;
