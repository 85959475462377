// Teakita Color Pelette
// Green Pea: #339059
// sushi: #CBDB37
// Henna: #858F2b
// Coral Blue: #39628F
// Baby Blue: #A0BBD9

const tekitaPalette = {
  greenPea: '#339059',
  sushi: '#CBDB37',
  henna: '#858F2b',
  coralBlue: '#39628F',
  babyBlue: '#A0BBD9',
}

const gray = {
  900: '#2B3445', // Main Text
  800: '#373F50', // Paragraph
  700: '#4B566B',
  600: '#7D879C', // Low Priority form Title/Text
  500: '#AEB4BE',
  400: '#DAE1E7', // Border
  300: '#E3E9EF',
  200: '#F3F5F9', // Line Stroke
  100: '#F6F9FC',
  white: '#FFFFFF',
};

const textColor = {
  primary: gray[900],
  secondary: gray[800],
  hint: gray[600],
  muted: gray[600],
  disabled: gray[400],
};

const bodyColor = {
  text: textColor.primary,
  default: gray[100],
  paper: gray['white'],
};

const primaryColor = {
  main: 'rgba(50, 143, 89,1)',
  light: 'rgba(50, 143, 89,0.2)',
  // dark: "#D23F57",
  dark: '#4F4CB6',
  text: '#ffffff',
  // 100: "#FCE9EC",
};

const secondaryColor = {
  main: 'rgba(43, 97, 97, 1)',
  light: 'rgba(43, 97, 97, 0.2)',
  dark: '#303A47',
  text: '#ffffff',
  900: '#041533',
  100: '#F3F6F9',
};

const warningColor = {
  main: '#FFCD4E',
  text: textColor.primary,
};
const errorColor = {
  main: 'rgba(255,0,0,1)',
  light: '#FFE1E6',
  text: textColor.primary,
};
const successColor = {
  main: 'rgba(51, 208, 103, 1)',
  light: 'rgba(51, 208, 103, 0.15)',
  text: textColor.primary,
};

const ghostColor = {
  main: gray[300],
  light: gray[800],
  text: gray[700]
}
const defaultColor = {
  light: textColor.secondary,
  main: textColor.primary,
  dark: textColor.primary,
  text: textColor.primary,
};

const teakita = {
  bright: '#14B339',
  main: '#328F59',
  light: '#7EB599',
  dark: '#2B6161',
  superlight: '#E1F0E8',
  hyperlink: '#00BAE3',
  danger: '#FF834E',
  dangerLight: '#FF9D66',
  text: '#ffffff',
};

export const colors = {
  default: defaultColor,
  primary: primaryColor,
  secondary: secondaryColor,
  warn: warningColor,
  error: errorColor,
  success: successColor,
  text: textColor,
  body: bodyColor,
  ghost: ghostColor,
  gray,
  teakita,
};
