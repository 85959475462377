// pages/maintenance.js
import React from 'react';

const MaintenancePage = () => {
  return (
    <div>
      <p>Sorry, the site is currently undergoing maintenance. Please check back later.</p>
    </div>
  );
};

export default MaintenancePage;
